import React, { useEffect, useRef, useState } from 'react';
import { Text, View, ExtendCSS, useTheme, Block, Extendable } from 'vcc-ui';
import { Click, Link } from '@vcc-www/buttons';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import SectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import Icon from '@vcc-www/large-icon';
import {
  AccordionSummary,
  AccordionDetails,
} from '@volvo-cars/react-accordion';

import {
  SpringCarouselProvider,
  SpringCarouselPane,
  useSpringCarousel,
  SpringCarouselArrow,
} from '@vcc-www/spring-carousel';
import { Sources } from '@vcc-www/utils/getMediaSources';
import Disclaimer from '@vcc-www/disclaimer';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { useTracker } from '@volvo-cars/tracking';
import GridHelper from '@vcc-www/grid-helper';
import { maxContentWidth8Columns } from '@vcc-www/constants/layout';
import SliderWithIconsProps, {
  SliderWithIconsItem,
} from './SliderWithIcons.props';

type ContentItemProps = {
  image: Sources;
  alt: string;
  id?: string;
  itemTitleVariant?: SliderWithIconsProps['itemTitleVariant'];
  dataAutoId?: string;
} & Pick<
  SliderWithIconsItem,
  'text' | 'title' | 'cta' | 'trackLabel' | 'file'
> &
  Extendable;

const ItemCta: React.FC<
  Pick<ContentItemProps, 'cta' | 'file' | 'trackLabel'>
> = ({ cta, file, trackLabel }) =>
  (cta?.href || file?.src) && cta?.text ? (
    <View extend={itemCtaCSS} data-autoid="sliderWithIcons:cta">
      <Link {...cta} href={file?.src || cta.href} trackEventLabel={trackLabel}>
        {cta.text}
      </Link>
    </View>
  ) : null;

const itemCtaCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  fromM: {
    paddingTop: 2 * baselineGrid,
  },
});

const ContentItem: React.FC<ContentItemProps> = ({
  title,
  text,
  image,
  alt,
  extend,
  id,
  cta,
  trackLabel,
  file,
  itemTitleVariant = 'regular',
}) => {
  return (
    <View
      extend={[contentItemCSS, extend]}
      justifyContent="space-between"
      direction={['column-reverse', 'column-reverse', 'row']}
      id={id}
    >
      <View basis="40%" paddingTop={[3, 3, 0]}>
        <Text
          variant={itemTitleVariant === 'small' ? 'ootah' : 'cook'}
          subStyle={itemTitleVariant === 'small' ? 'emphasis' : 'standard'}
          data-autoid="sliderWithIcons:headline"
        >
          {title}
        </Text>
        <Text
          extend={textCSS}
          variant="columbus"
          data-autoid="sliderWithIcons:text"
        >
          {text}
        </Text>
        <ItemCta cta={cta} file={file} trackLabel={trackLabel} />
      </View>
      <Image_DEPRECATED
        extend={imageCSS}
        sources={image}
        alt={alt}
        loading="native-lazy"
        sizes={{
          fromM: '50vw',
        }}
        data-autoid="sliderWithIcons:image"
      />
    </View>
  );
};

const contentItemCSS: ExtendCSS = ({ theme: { baselineGrid, color } }) => ({
  width: '100%',
  background: color.background.secondary,
  padding: baselineGrid * 3,
  fromM: {
    paddingTop: baselineGrid * 10,
    paddingBottom: baselineGrid * 10,
  },
});

const textCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  paddingTop: baselineGrid * 2,
  whiteSpace: 'pre-line',
});

const DesktopSlider: React.FC<
  Pick<SliderWithIconsProps['content'], 'items'> & {
    itemTitleVariant?: SliderWithIconsProps['itemTitleVariant'];
    iconGridLayoutSize?: SliderWithIconsProps['iconGridLayoutSize'];
  }
> = ({ items = [], itemTitleVariant, iconGridLayoutSize }) => {
  const { current, setCurrent } = useSpringCarousel();
  const { baselineGrid } = useTheme();

  const getArrowTrackingLabel = (data: {
    current: number;
    direction: string;
  }) => {
    const { current, direction } = data;
    return `${direction} | ${items[current]?.analyticsLabel}`;
  };

  return (
    <>
      {/* Carousel on desktop */}
      {items.length > 1 && (
        <View
          extend={iconWrapperCSS(iconGridLayoutSize)}
          direction="row"
          justifyContent="space-around"
        >
          {items.map((item, i) => (
            <Click
              aria-label={item.title}
              key={item.title}
              onClick={() => setCurrent(i + 1)}
              data-autoid={`sliderWithIcons:title${i + 1}`}
              aria-selected={i === current - 1 ? 'true' : 'false'}
              trackEventLabel={'tab | ' + item.analyticsLabel}
              // @ts-ignore Check if this is correct value to track for ga4
              trackEventAction="click"
              extend={{ width: '100%' }}
            >
              <View
                alignItems="center"
                height={90}
                maxWidth={150}
                extend={{ margin: 'auto' }}
              >
                {item.icon && (
                  <View
                    extend={iconCSS}
                    data-autoid={`sliderWithIcons:icon${i + 1}`}
                  >
                    <Icon name={item.icon as any} />
                  </View>
                )}

                <Block
                  extend={iconTextWrapperCSS({ active: current === i + 1 })}
                >
                  <Text
                    variant="kelly"
                    subStyle={current === i + 1 ? 'emphasis' : 'standard'}
                    extend={iconTextCSS}
                  >
                    {item.iconText || item.title}
                  </Text>
                </Block>
              </View>
            </Click>
          ))}
        </View>
      )}

      <View
        direction="row"
        extend={carouselContainerCSS}
        data-autoid="springCarouselPane:container"
      >
        <SpringCarouselPane itemSpacing={baselineGrid * 12 + 1}>
          {items.map((item, index) => (
            <div
              key={item.title}
              data-autoid={`sliderWithIcons:card${index + 1}`}
            >
              <ContentItem
                image={{
                  default:
                    item.mobileImage?.src ?? item.desktopImage?.src ?? '',
                  fromM: item.desktopImage?.src,
                }}
                title={item.title}
                text={item.text}
                alt={item.mobileImage?.alt ?? item.desktopImage?.alt ?? ''}
                id={item.sliderItem}
                cta={item.cta}
                trackLabel={getItemTrackingLabel(item, index)}
                file={item.file}
                itemTitleVariant={itemTitleVariant}
              />
            </div>
          ))}
        </SpringCarouselPane>
        <SpringCarouselArrow
          direction="left"
          trackEventLabel={getArrowTrackingLabel}
        />

        <SpringCarouselArrow
          direction="right"
          trackEventLabel={getArrowTrackingLabel}
        />
      </View>
    </>
  );
};

export const getItemTrackingLabel = (
  item: SliderWithIconsItem,
  index: number,
) =>
  item.trackLabel ||
  `link | ${index + 1} | ${item.cta?.text || item.file?.mimeType} | ${
    item.cta?.href || item.file?.src
  }`;

const carouselContainerCSS: ExtendCSS = ({
  theme: { baselineGrid, color },
}) => ({
  untilM: { display: 'none' },
  position: 'relative',
  paddingLeft: baselineGrid * 12,
  paddingRight: baselineGrid * 12,
  backgroundColor: color.background.secondary,
});

const iconCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  height: 50,
  paddingBottom: baselineGrid,
});

const iconWrapperCSS =
  (iconGridLayoutSize: SliderWithIconsProps['iconGridLayoutSize']): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    untilM: { display: 'none' },
    maxHeight: '90px',
    marginBottom: baselineGrid * 3,
    ...(iconGridLayoutSize === '8' && {
      maxWidth: maxContentWidth8Columns,
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
  });

const iconTextCSS: ExtendCSS = {
  textAlign: 'center',
};

const iconTextWrapperCSS = ({ active }: { active: boolean }): ExtendCSS => ({
  opacity: active ? 1 : 0.5,
  transform: `scale(${active ? 1 : 0.98})`,
  transition: `opacity 500ms ease-out, transform 500ms ease-out`,
});

const imageCSS: ExtendCSS = {
  flexBasis: '50%',
  display: 'flex',
  alignItems: 'center',
};

const SLIDER_ITEM = 'sliderItem';

const SliderWithIcons: React.FC<SliderWithIconsProps> = ({
  content,
  itemTitleVariant,
  iconGridLayoutSize = '12',
  ...params
}) => {
  const { items = [], disclaimer, title } = content;
  const onlyOne = items.length === 1;
  const first = items[0];
  const containerRef = useRef<HTMLElement>(null);
  const { searchParams } = useVolvoCarsUrl();
  const [displayItems, setDisplayItems] = useState(items);
  const tracker = useTracker();

  const accordionTrack = useTracker({ action: 'Click' });

  const trackSwipeEvent = ({
    type,
    previous,
    current,
  }: {
    type: string;
    previous: number;
    current: number;
  }) => {
    if (type === 'swipe-pane') {
      const direction = previous < current ? 'right' : 'left';
      const itemTitle = items?.[current - 1]?.analyticsLabel;

      tracker.interaction({
        eventAction: `swipe`,
        eventLabel: `${direction} | ${itemTitle}`,
      });
    }
  };

  useEffect(() => {
    if (searchParams.has(SLIDER_ITEM)) {
      const itemId = searchParams.get(SLIDER_ITEM);
      const firstItem = items.find(({ sliderItem }) => sliderItem === itemId);
      if (firstItem) {
        setDisplayItems([
          firstItem,
          ...items.filter(({ sliderItem }) => sliderItem !== itemId),
        ]);
      }
    }
  }, [searchParams, items]);

  return (
    <SectionContained ref={containerRef} {...params} extend={sectionCSS}>
      {title && (
        <GridHelper size={7}>
          <Text variant="ootah" as="h2" subStyle="emphasis" extend={titleCSS}>
            {title}
          </Text>
        </GridHelper>
      )}

      {/* Accordion on mobile */}
      {onlyOne ? (
        <ContentItem
          key={first.title}
          image={{
            default: first.mobileImage?.src ?? first.desktopImage?.src ?? '',
            fromM: first.desktopImage?.src,
          }}
          title={first.title}
          text={first.text}
          alt={first.mobileImage?.alt ?? first.desktopImage?.src ?? ''}
          extend={mobileSingleItemCSS}
          itemTitleVariant={itemTitleVariant}
        />
      ) : (
        displayItems.map(({ cta, title, file, ...item }, i) => (
          <AccordionDetails
            className="mx-24 md:hidden"
            key={i}
            defaultOpen={i === 0}
          >
            <AccordionSummary
              onClick={() => {
                const eventLabel = `${i + 1} | ${title}`;
                accordionTrack.interaction({
                  eventLabel,
                });
              }}
            >
              <h2 className="heading-2" aria-label={title}>
                {title}
              </h2>
            </AccordionSummary>
            <div className="bg-secondary">
              <Block extend={accordionItemDetails}>
                <Image_DEPRECATED
                  alt={item.mobileImage?.alt ?? ''}
                  src={item.mobileImage?.src ?? ''}
                  data-autoid="sliderWithIcons:image"
                />

                <Text
                  variant="ootah"
                  subStyle="emphasis"
                  extend={accordionTitleCSS}
                  data-autoid="sliderWithIcons:headline"
                >
                  {title}
                </Text>
                <Text
                  variant="columbus"
                  extend={accordionTextCSS}
                  data-autoid="sliderWithIcons:text"
                >
                  {item.text}
                </Text>
                {[
                  cta?.text && (
                    <ItemCta
                      key={1}
                      cta={cta}
                      file={file}
                      trackLabel={getItemTrackingLabel(
                        { cta, title, file, ...item },
                        i,
                      )}
                    />
                  ),
                ].filter(Boolean)}
              </Block>
            </div>
          </AccordionDetails>
        ))
      )}

      <SpringCarouselProvider onChange={trackSwipeEvent}>
        <DesktopSlider
          items={displayItems}
          itemTitleVariant={itemTitleVariant}
          iconGridLayoutSize={iconGridLayoutSize}
        />
      </SpringCarouselProvider>
      {disclaimer && <Disclaimer extend={disclaimerCSS} text={disclaimer} />}
    </SectionContained>
  );
};

const titleCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  textAlign: 'center',
  padding: `${baselineGrid * 2}px 0`,
  untilM: {
    textAlign: 'left',
  },
});
const sectionCSS: ExtendCSS = { overflowX: 'hidden' };

const accordionTitleCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  display: 'inline-block',
  paddingTop: baselineGrid * 4,
});
const accordionTextCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  paddingTop: baselineGrid,
  display: 'inline-block',
});

const mobileSingleItemCSS: ExtendCSS = {
  fromM: {
    display: 'none',
  },
};
const disclaimerCSS: ExtendCSS = ({ theme }) => ({
  paddingTop: theme.baselineGrid * 2,
});

const accordionItemDetails: ExtendCSS = ({ theme }) => ({
  paddingBottom: 0,
  paddingTop: theme.baselineGrid * 3,
  paddingLeft: theme.baselineGrid * 2,
  paddingRight: theme.baselineGrid * 2,
});

export default SliderWithIcons;
